<template>
  <div>
    <!-- Task Card Header -->
    <b-card class="card" title="Tambah Materi Kelas">
      <div class="mt-2">
        <b-row>
          <!-- Judul Materi -->
          <b-col lg="12 mb-1">
            <label class="d-block">Judul Materi</label>
            <b-form-input
              v-model="formData.materialTitle"
              placeholder="Masukkan Judul Materi"
              :state="formErrors.materialTitle.state"
            />
            <b-form-invalid-feedback v-show="!formErrors.materialTitle.state">
              {{ formErrors.materialTitle.message }}
            </b-form-invalid-feedback>
          </b-col>

          <!-- Deskripsi Materi -->
          <b-col lg="12 mb-1">
            <label class="d-block">Deskripsi Materi</label>
            <b-form-textarea
              v-model="formData.materialDescription"
              placeholder="Masukkan Deskripsi Materi"
              :state="formErrors.materialDescription.state"
            />
            <b-form-invalid-feedback
              v-show="!formErrors.materialDescription.state"
            >
              {{ formErrors.materialDescription.message }}
            </b-form-invalid-feedback>
          </b-col>

          <!-- Jenis Materi -->
          <b-col lg="12">
            <label class="d-block">Pilih Jenis Materi</label>
            <b-row class="mt-0">
              <!-- File -->
              <b-col lg="4">
                <b-card
                  class="mt-1 card-material-type"
                  :class="
                    selectedMaterialType === 'file'
                      ? 'active-material-type border-primary'
                      : 'border-light-secondary'
                  "
                  @click="triggerFormInput('file')"
                >
                  <div class="d-flex flex-column align-items-center p-2 ">
                    <b-avatar size="lg" rounded="sm" variant="light-primary">
                      <i class="card-icon-lg ti ti-file-upload"></i>
                    </b-avatar>
                    <h4 class="pt-1">
                      File
                    </h4>
                    <span>
                      (pdf, docx, xlsx, pptx)
                    </span>
                  </div>
                  <div>
                    <b-form-checkbox
                      v-model="selectedMaterialType"
                      class="custom-control-primary"
                      value="file"
                      plain
                    />
                  </div>
                </b-card>
              </b-col>

              <!-- Video -->
              <b-col lg="4">
                <b-card
                  class="mt-1 card-material-type"
                  :class="
                    selectedMaterialType === 'video'
                      ? 'active-material-type border-primary'
                      : 'border-light-secondary'
                  "
                  @click="triggerFormInput('video')"
                >
                  <div class="d-flex flex-column align-items-center p-2 ">
                    <b-avatar size="lg" rounded="sm" variant="light-info">
                      <i class="card-icon-lg ti ti-brand-zoom" />
                    </b-avatar>
                    <h4 class="pt-1">
                      Video
                    </h4>
                    <span>
                      (mp4, mkv, dll)
                    </span>
                  </div>
                  <div>
                    <b-form-checkbox
                      v-model="selectedMaterialType"
                      class="custom-control-primary"
                      value="video"
                      plain
                    />
                  </div>
                </b-card>
              </b-col>

              <!-- Link -->
              <b-col lg="4">
                <b-card
                  class="mt-1 card-material-type"
                  :class="
                    selectedMaterialType === 'link'
                      ? 'active-material-type border-primary'
                      : 'border-light-secondary'
                  "
                  @click="triggerFormInput('link')"
                >
                  <div class="d-flex flex-column align-items-center p-2 ">
                    <b-avatar size="lg" rounded="sm" variant="light-success">
                      <i class="card-icon-lg ti ti-link" />
                    </b-avatar>
                    <h4 class="pt-1">
                      Link
                    </h4>
                    <span>
                      (youtube.com, drive.google.com, dll)
                    </span>
                  </div>
                  <div>
                    <b-form-checkbox
                      v-model="selectedMaterialType"
                      class="custom-control-primary"
                      value="link"
                      plain
                    />
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>

          <!-- File Form Input -->
          <b-col
            v-show="selectedMaterialType === 'file' && filelist.length === 0"
            lg="12"
          >
            <label class="d-block">Upload File</label>
            <div class="drop-file-con">
              <b-card
                class="drop-file p-4 mt-2"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
              >
                <div class="d-flex flex-column align-items-center">
                  <b-avatar size="lg" rounded="sm" variant="light-primary">
                    <i class="card-icon-lg ti ti-file-upload"></i>
                  </b-avatar>
                  <h3 class="pt-1 small-gap-bottom">
                    Seret file di sini
                  </h3>
                  <span class="small-gap-bottom">
                    (Format file yang didukung: .pdf, .doc, .docx, .xls, .xlsx,
                    .ppt, .pptx)
                  </span>
                  <span class="small-gap-bottom">
                    Materi maksimal 100 MB
                  </span>

                  <b-button variant="primary" @click="$refs.file.click()">
                    Upload File</b-button
                  >
                </div>
              </b-card>
            </div>
          </b-col>

          <!-- Video Form Input -->
          <b-col
            v-show="selectedMaterialType === 'video' && filelist.length === 0"
            lg="12"
          >
            <label class="d-block">Upload Video</label>
            <div class="drop-file-con">
              <b-card
                class="drop-file p-4 mt-2"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
              >
                <div class="d-flex flex-column align-items-center">
                  <b-avatar size="lg" rounded="sm" variant="light-info">
                    <i class="card-icon-lg ti ti-brand-zoom"></i>
                  </b-avatar>
                  <h3 class="pt-1 small-gap-bottom">
                    Seret file di sini
                  </h3>
                  <span class="small-gap-bottom">
                    (Format file yang didukung: .mp4, .mkv)
                  </span>
                  <span class="small-gap-bottom">
                    Materi maksimal 100 MB
                  </span>
                  <b-button variant="info" @click="$refs.file.click()">
                    Upload File</b-button
                  >
                </div>
              </b-card>
            </div>
          </b-col>

          <!-- File Form Input -->
          <b-col v-show="selectedMaterialType === 'link'" lg="12">
            <label class="d-block">Link Materi</label>
            <div>
              <b-form-input
                v-model="formData.materialURL"
                placeholder="e.g. https://www.youtube.com/watch?v=1"
                type="text"
              />
            </div>
          </b-col>
        </b-row>

        <!-- Display Selected File -->
        <b-row v-show="selectedMaterialType !== 'link' && filelist.length != 0">
          <b-col lg="12 ">
            <label class="d-block">File / Video</label>
          </b-col>
          <b-col lg="2">
            <b-card
              class="border-light-secondary d-flex flex-column text-center"
              v-for="(file, index) in filelist"
              :key="index"
            >
              <b-button
                class="remove-button-x"
                variant="danger"
                size="sm"
                @click="remove(index)"
              >
                <i class="ti ti-x"></i>
              </b-button>
              <b-avatar rounded :variant="fileType(getExtension(file.name))">
                <span class="p-2"> {{ getExtension(file.name) }}</span>
              </b-avatar>
              <div class="mt-1">
                <span>
                  {{ ellipsis(file.name) }}
                </span>
              </div>
            </b-card>

            <!-- Hidden Input -->
            <input
              ref="file"
              hidden
              type="file"
              :accept="
                selectedMaterialType === 'file'
                  ? '.pdf,.doc, .docx,.ppt, .pptx,.xls, .xlsx'
                  : '.mp4, .mkv'
              "
              @change="onChange()"
            />
          </b-col>
        </b-row>

        <div class="mt-1">
          <b-button variant="primary" class="float-right " @click="submitForm">
            <div class="d-flex align-items-center ">
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              <i
                v-show="!isLoading"
                class="ti ti-device-floppy small-gap-right"
              />
              Simpan
            </div>
          </b-button>
        </div>
      </div>
    </b-card>

    <!-- toast -->
    <b-toast
      id="toast-upload-material"
      variant="primary"
      solid
      no-auto-hide
      no-close-button
    >
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-center mr-25">
          <strong class="mr-auto">
            Upload Materi...
            <b id="upload-percentage-title">0</b>
            %
          </strong>
          <button
            @click="cancelUpload()"
            type="button"
            aria-label="Close"
            class="close ml-auto mb-1"
          >
            ×
          </button>
        </div>
      </template>
      <span>
        Sedang Dalam Proses Upload, Mohon Tunggu ... (
        <b id="upload-percentage">0</b>% )
      </span>
    </b-toast>
  </div>
</template>

<script>
// * Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BBadge,
  BAlert,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// * Utils
import axios from 'axios'
import { checkAuthorizeRole } from '@/auth/utils'
import { avatarText, fileType } from '@core/utils/filter'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BAlert,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BSpinner,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      // state
      isLoading: false,
      selectedMaterialType: 'file',
      request: null,

      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,

      // form data
      filelist: [],
      formErrors: {
        materialTitle: { state: null, message: '' },
        materialDescription: { state: null, message: '' },
      },
      formData: {
        materialTitle: '',
        materialDescription: '',
        materialURL: '',
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    const { breadcrumb } = to.meta

    to.meta.breadcrumb[breadcrumb.length - 2].params = {
      id: to.params.id,
      contentId: to.params.contentId,
    }
    to.meta.breadcrumb[breadcrumb.length - 3].params = { id: to.params.id }
    next()
  },
  methods: {
    avatarText,
    fileType,
    getExtension(filename) {
      return filename.split('.').pop()
    },
    ellipsis(text) {
      if (text.length > 6) {
        return `${text.substring(0, 10)}...${text.substr(
          text.lastIndexOf('.') + 1
        )}`
      }
      return text
    },
    triggerFormInput(type) {
      this.selectedMaterialType = type

      if (type === 'link') {
        this.filelist = []
      } else {
        this.formData.materialURL = ''
      }
    },
    onChange() {
      this.filelist = [...this.$refs.file.files]
    },
    remove(i) {
      this.filelist.splice(i, 1)
      const allDropFileContainer = document.querySelectorAll('.drop-file')

      allDropFileContainer.forEach((dropFile) => {
        dropFile.classList.remove('bg-drop-file')
      })
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-drop-file')) {
        event.currentTarget.classList.add('bg-drop-file')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove('bg-drop-file')
    },
    drop(event) {
      event.preventDefault()
      const droppedFiles = event.dataTransfer.files

      if (droppedFiles.length > 1) {
        event.currentTarget.classList.remove('bg-drop-file')
        this.showToast(
          'danger',
          'XIcon',
          'Gagal',
          'File tidak boleh lebih dari satu'
        )
      } else {
        droppedFiles.forEach((element) => {
          const fileExtension = element.name.split('.').pop()
          const allowedExtension = [
            'pdf',
            'doc',
            'docx',
            'ppt',
            'pptx',
            'xls',
            'xlsx',
            'mp4',
            'mkv',
          ]

          if (allowedExtension.includes(fileExtension)) {
            this.filelist.push(element)
          } else {
            event.currentTarget.classList.remove('bg-drop-file')
            this.showToast('danger', 'XIcon', 'Gagal', 'File tidak didukung')
          }
        })
      }
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      let isValid = true
      if (this.formData.materialTitle === '') {
        this.formErrors.materialTitle = {
          state: false,
          message: 'Nama Materi tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.materialTitle = {
          state: null,
          message: '',
        }
      }

      if (!isValid) {
        this.showToast('danger', 'XIcon', 'Error', 'Form tidak valid')
        this.isLoading = false
      } else {
        this.isLoading = true
      }

      return isValid
    },
    async submitForm() {
      this.isLoading = true
      if (this.validateForm()) {
        // cancel previous request
        this.cancelRequest()

        // API basic configuration
        const baseUrl = `${process.env.VUE_APP_BASE_API_URL}`
        const url = `${baseUrl}/classrooms/${this.classroomId}/class-contents/${this.contentId}/class-content-materials`

        const axiosSource = axios.CancelToken.source()
        this.request = { cancel: axiosSource.cancel }
        const config = {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
          cancelToken: axiosSource.token,
          onUploadProgress(progressEvent) {
            // eslint-disable-next-line radix
            const uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            )

            document.getElementById(
              'upload-percentage'
            ).innerHTML = uploadPercentage
            document.getElementById(
              'upload-percentage-title'
            ).innerHTML = uploadPercentage
          },
        }

        // Create form data
        const data = new FormData()
        data.append('title', this.formData.materialTitle)
        data.append('class_content_id', this.contentId)
        data.append('description', this.formData.materialDescription)

        console.log(this.formData.materialURL)
        // Check if material type is link
        if (this.selectedMaterialType === 'file') {
          data.append('file', this.filelist[0])
        } else if (this.selectedMaterialType === 'link') {
          data.append('material_url', this.formData.materialURL)
        } else {
          data.append('video_url', this.filelist[0])
        }

        this.$bvToast.show('toast-upload-material', {
          solid: true,
          appendToast: true,
          toaster: 'b-toaster-bottom-right',
        })

        // create content material
        await axios
          .post(url, data, config)
          .then((response) => {
            // stop loading
            this.isLoading = false

            // show success toast
            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Materi berhasil ditambah'
            )

            this.$bvToast.hide('toast-upload-material')

            // redirect to classroom detail
            this.$router.push({
              name: checkAuthorizeRole('classroom-detail-material'),
              params: { id: this.classroomId, contentId: this.contentId },
            })
          })
          .catch((error) => {
            this.isLoading = false

            // show success toast
            this.showToast(
              'danger',
              'XIcon',
              'Gagal',
              error.response.data.message
            )
          })
      }
    },

    reset() {
      this.cancelRequest()
      this.request = null
    },
    cancelRequest() {
      if (this.request) this.request.cancel()
    },

    cancelUpload() {
      this.reset()
      this.cancelRequest()
      this.$bvToast.hide('toast-upload-material')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.line-height-small {
  line-height: 1.5rem;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.preview-material {
  max-height: 200px;
  object-fit: fill;
}

.preview-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size: 2rem;
}

.vertical-scrollable > .row {
  position: relative;
  height: calc(100vh - 330px);
  width: 100%;
  overflow-y: scroll;
}

.active-material-type {
  border-width: 2px !important;
}

.drop-file {
  cursor: pointer;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 2px dashed #d3d3d3 !important;
  border-radius: 0.428rem !important;
}
.card-material-type {
  cursor: pointer;
}

.remove-button-x {
  padding: 10px 10px;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

.bg-drop-file {
  background-color: #c9ffe7 !important;
}
</style>
